/* eslint-disable */
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getTranslate } from 'helpers/translate'
import './Sidebar.scss'
import { use } from 'i18next'

declare var jQuery: any

const isActive = (path, location) => (path === location ? true : false)

const toggleMenu = e => {
  e.preventDefault()
  jQuery(e.target)
    .parents('.has-sub')
    .toggleClass('opened')
  jQuery(e.target)
    .parents('.has-sub')
    .find('ul')
    .toggleClass('visible')
}

const toggleSidebar = e => {
  e.preventDefault()
  jQuery('.page-container').toggleClass('sidebar-collapsed')
}

type Props = {
  user: User
  location: {
    pathname: string
  }
}

const Sidebar: React.FC<Props> = ({ user, location }) => {
  const { t, i18n } = useTranslation()

  const { pathname } = location
  const { products = [], company, legalEntity } = user
  const advantageProducts = products.filter(product => product.PL === 'Exent')
  const otherProducts = products.filter(product => product.PL === 'Top')

  const isAdvantageOpened = advantageProducts.find(product =>
    pathname.endsWith(product.category.alias)
  )
  const isOtherOpend = otherProducts.find(product =>
    pathname.endsWith(product.category.alias)
  )

  const logo = (legalEntity && legalEntity.logo) || company.logo
  const name = (legalEntity && legalEntity.name) || company.name

  const style: any = {}
  const styleActive: any = {}
  const styleSubActive: any = {}
  if (company.colorBar) {
    style.backgroundColor = company.colorBar
    styleSubActive.border = `2px solid color-mix(in srgb, ${company.colorBar} 100%, #000 40%)`
    styleActive.borderRight = `6px solid color-mix(in srgb, ${company.colorBar} 100%, #000 40%)`
  }

  const hideCompensation = company && company['hideCompensation']

  return (
    <div className="sidebar-menu my-sidebar p-2" style={style}>
      <div className="sidebar-menu-inner">
        <header className="logo-env">
          <div className="logo">
            <Link to="/">
              {logo ? (
                <img
                  src={`/api/storage/images/${logo}`}
                  alt=""
                />
              ) : (
                <h1>{name}</h1>
              )}
            </Link>
          </div>

          <div className="sidebar-collapse">
            <a
              href="#toggle"
              className="sidebar-collapse-icon"
              onClick={toggleSidebar}
            >
              <i className="entypo-menu" />
            </a>
          </div>

          <div className="sidebar-mobile-menu visible-xs">
            <a className="with-animation">
              <i className="entypo-menu" />
            </a>
          </div>
        </header>

        <ul className="main-menu" id="main-menu">
          <li className={`root-level`} style={isActive(pathname, '/home') ? styleActive : {}}>
            <Link to="/home">
              <i className="entypo-home" />
              <span className="title">{t('MENU.HOME')}</span>
            </Link>
          </li>
          {!hideCompensation && <li className={`root-level`} style={isActive(pathname, '/compensation') ? styleActive : {}}>
            <Link to="/compensation">
              <i className="entypo-chart-pie" />
              <span className="title">{t('MENU.COMPENSATION')}</span>
            </Link>
          </li>}
          <li className={`root-level`} style={isActive(pathname, '/documents') ? styleActive : {}}>
            <Link to="/documents">
              <i className="entypo-drive" />
              <span className="title">{t('MENU.DOCUMENT')}</span>
            </Link>
          </li>
          <li className={`root-level`} style={isActive(pathname, '/profile') ? styleActive : {}}>
            <Link to="/profile">
              <i className="entypo-user" />
              <span className="title">{t('MENU.PROFILE')}
              </span>
            </Link>
          </li>

          {advantageProducts.length > 0 && (
            <li
              className={`has-sub root-level ${isAdvantageOpened &&
                'opend '}`}
              style={isAdvantageOpened ? styleSubActive : {}}
            >
              {/* eslint-disable-next-line*/}
              <a href="#link" onClick={toggleMenu}>
                <i className="entypo-box " />
                <span className="title">{t('MENU.ADVANTAGE_PRODUCTS')} {isAdvantageOpened ? 'true' : 'false'}</span>
              </a>
              <ul>
                {advantageProducts.map(product => (
                  <li
                    key={product.name}
                  >
                    <Link to={`/products/${product.category.alias}`}>
                      <span className="title">
                        {getTranslate(product.category, 'name', i18n.language)}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}

          {otherProducts.length > 0 && (
            <li
              className={`has-sub root-level ${isOtherOpend && 'opend'}`}
              style={isOtherOpend ? styleSubActive : {}}
            >
              <a href="#link" onClick={toggleMenu}>
                <i className="entypo-dot-3" />
                <span className="title">{t('MENU.OTHER_PRODUCTS')}</span>
              </a>
              <ul>
                {otherProducts.map(product => (
                  <li
                    key={product.name}
                  >
                    <Link to={`/products/${product.category.alias}`}>
                      <span className="title">
                        {getTranslate(product.category, 'name', i18n.language)}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>




        <div className="legal-buttons">
          <a href="/assets/legal.pdf" target="_blank">
            {t('MENU.LEGAL')}
          </a>
          <span className="legal-buttons-sep">|</span>

          <a href="/assets/privacy.pdf" target="_blank">
            {t('MENU.PRIVACY')}
          </a>
          <span className="legal-buttons-sep">|</span>
          <a href="/assets/cookielegal.pdf" target="_blank">
            Cookies
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Sidebar)
