export default {
  MENU: {
    HOME: 'Inici',
    DOCUMENT: 'Documentació',
    PROFILE: 'Informació personal',
    ADVANTAGE_PRODUCTS: 'Productes amb avantatges fiscals',
    OTHER_PRODUCTS: 'Altres productes',
    LEGAL: 'Avís legal',
    PRIVACY: 'Política de privacitat',
    COOKIE_MESSAGE: 'Aquesta pàgina web fa servir cookies per millorar l´experiència',
    COMPENSATION: 'Compensació',
    FLEXIBLE: 'Retribució Flexible',
    DOCUMENTATION: 'Documentació',
    PERSONAL: 'Informació Personal',
    MY_COMPENATION: 'La meva compensació',
    EXPENSE_MANAGEMENT: 'Gestió de despeses',
    CORPORATE_SOCIAL_RESPONSIBILITY: 'Responsabilitat social corporativa',
    HELLO: 'Hola',
    WELCOME_MESSAGE: `Benvingut al vostre pla de remuneració flexible. A l'apartat “Documentació” trobareu tota la informació necessària. Recordeu que primer heu d'omplir la secció “Informació personal”`,
  },
  HEADER: {
    SAVED: 'Estalviats',
    PENDING_SAVING: 'Possibles d´estalivar',
    LIMIT: 'Límit de despesa',
    REACHED: 'assolit',
    LANGUAGE: 'Idioma',
    CHECKOUT: 'Tramitar comanda',
    LOGOUT: 'Tancar sessió',
    HELP: 'Ajuda',
    CONTACT: "Contacta'ns",
  },
  HOME: {
    MISSING_PROFILE_INFO: 'Informació d\'usuari incompleta: Si us plau, comproveu que té omplert l\'email / telèfon en el seu perfil',
    CURRENT_SITUATION: 'Situació',
    NEWS: 'Notícies',
    PRODUCTS_WITH_TAX: 'Productes amb advantatge fiscal',
    ALERTS: 'Alertes',
    CONTRACTED_PRODUCTS: 'Producte contractat',
    PENDING_SIGNATURE: 'Pendent de signar',
    PENDING_PRODUCTS: 'Pendent de contractar',
    SAVING: 'Estalviats',
    PENDING_SAVING: 'Possibles d´estalviar',
    NON_SIMULATED_PRODUCT: 'Producte no simulat',
    PENDING_SIMULATIONS: 'Simulacions Pendents',
    NO_ACTIVE_ALERTS: 'No té alertes',
    PRODUCT: 'Producte',
    Status: 'Estat',
    END_DATE: 'Data final',
    Expiring: 'Expirant',
    Go: 'Anar',
    MISSING_DOCUMENT: 'Falta documentació',
    ATTACH_INVOICE: 'Adjuntar factura mes',
    PENDING_CART: 'Pendent de contractar',
    PENDING_REVIEW: 'Pendent de revisió',
    CONTRACTED: 'Contractat',
    CANCELLED: 'Cancel·lat',
  },
  LIFE_AXA: {
    FALLCC: 'Defunció per qualsevol causa',
    FALLA: 'Defunció per accident',
    FALLAC: 'Defunció per accident circulació',
    IPAA: 'Incapacitat Permanent Absoluta per accident',
    IPAAC: 'Incapacitat Permanent Absoluta per accident circulació',
    IPACC: 'Incapacitat Permanent Absoluta per qualsevol causa',
    GINV: 'Gran Invalidesa',
    MANDATORY: 'Obligatori',
    MANDATORY_2: 'obligatori per contractar',
    AGE_LIMIT: 'No disponible per a la teva edat',
    CANNOT_EXCEED: 'no pot excedir',
    CANNOT_LESS_FALLA: 'No podrà ser inferior a 35.000 €'
  },

  COMPENSATION:{
    Benefits:"Beneficis Socials",
    TotalCompensation: "Total Compensació",
    TotalSecurityCost: "Cost de la seguretat social"

  },
  DOCUMENT: {
    Documents: 'Documents',
    DETAIL_ACTION: 'Detall per producte',
    DETAIL: 'Detall',
    ID: 'Id',
    YEAR: 'Any',
    NAME: 'Nom',
    CREATED_BY: 'Creat per',
    CREATED_AT: 'Creat el',
    LAST_MODIFICATION: 'Última modificació',
    Admin: 'Administrador',
    no_documents: 'No hi ha documents penjats',
    no_products: 'No hi ha productes contractats',
  },
  profile: {
    user_tab: 'Informació d´usuari',
    employee_tab: 'Informació personal',
    family_tab: 'Informació familiar',
    office: 'Oficina',
    incorrect_password: 'La contrasenya no compleix amb les mesures mínimes de seguretat: Ha de tenir un mínim de 8 caracters, contenir números i lletres, al menys una lletra majúscula/minúscula i sense espais',
    incorrect_repeat_password: 'La contrasenya no coincideix',
    password_deprecated: 'La contrasenya ha caducat. Si us plau, introduïu una nova contrasenya',
    collective: 'Colectiu',
    legal_entity: 'Entitat legal',
    email: 'Email',
    password: 'Contrasenya',
    repeat_password: 'Repetir contrasenya',
    id: 'DNI',
    phone: 'Telèfon móbil',
    bank: 'Número de compte bancari',
    nationality: 'Nacionalitat',
    birthday: 'Data de naixement',
    Gender: 'Sexe',
    Male: 'Home',
    Female: 'Dona',
    disability: 'Discapacitat',
    No: 'No',
    Yes: 'Si',
    marital_status: 'Estat civil',
    Single: 'Solter',
    Married: 'Casat',
    Divorced: 'Divorciat',
    Widowed: 'vidu',
    Address1: 'Direcció 1',
    Address2: 'Direcció 2',
    City: 'Població',
    Country: 'Pais',
    Province: 'Provincia',
    postal_code: 'Codi postal',
    fixed_salary: 'Salari fixe',
    flexible_bag: 'Borsa flexible',
    top_bag: 'Borsa superior',
    variable_salary: 'Salari variable',
    number_payments: 'Número de pagues',
    social_security: 'Número de seguretat social',
    employee_number: 'Número d´empleat',
    cost_center: 'Centre de cost',
    renting: 'Renting',
    private_use: 'Ús privatiu',
    relationship: 'Parentiu',
    spouse: 'Cònjuge',
    child: 'Fill/filla',
    domestic_partner: 'Parella de fet',
    name: 'Nom',
    surname: 'Cognom',
    first_name: 'Nom',
    last_name: 'Cognom',
    last_name_2: 'Segon Cognom',
    address: 'Direcció',
  },
  product_names: {
    'health-insurance': 'Salut',
    transport: 'Transport',
    food: 'Menjar',
    kindergarten: 'Guarderia',
    formation: 'Formació',
    renting: 'Renting',
    'life-insurance': 'Vida',
    'life-insurance-axa': 'Vida',
    'saving-plan': "Estalvi",
    gym: 'Gimnàs',
    lottery: 'Loteria',
    informative: 'Informatiu',
  },
  buttons: {
    accept: 'ACCEPTAR',
    accept_lower: 'Acceptar',
    consent_lower: 'Entès',
    close: 'TANCAR',
    add_cart: 'AFEGIR A LA CISTELLA',
    update_cart: 'ACTUALITZAR LA CISTELLA',
    remove_cart: 'TREURE DE LA CISTELLA',
    update_contract: 'ACTUALITZAR EL CONTRACTE',
    reset: 'RESET',
    see_details: 'Aceptar la cláusula de privacidad',
    sign: 'SIGNAR',
    back: 'ENRERE',
    update: 'ACTUALITZAR',
    cancel: 'CANCELAR',
    download: 'DESCARREGAR',
    click_here: 'CLICK AQUÍ',
    back_simulation: 'TORNAR A SIMULAR',
    next_step: 'CONTRACTAR',
    add_child: 'AFEGIR FILL',
    add_family: 'ADD FAMILY',
    select: 'SELECCIONAR',
    delete: 'ELIMINAR',
    add_member: 'AFEGIR MEMBRE',
    reset_password: 'Restablir contrasenya',
    forgot_password: 'Has oblidat la contrasenya?',
    back_to_login: 'Tornar a inici',
    change_language: 'canviar',
  },
  calculator: {
    header: 'SIMULADOR D´ESTALVI IRPF',
    increase_dis_income: 'Increment net disponible',
    equi_salary_increase: 'Increment salari equivalent',
    not_available:
      'Calculadora no disponible per: Informació salarial incompleta',
    edit_profile: 'Editar informació del salari en el perfil dels usuaris.',
    fixed_salary: 'Salari fixe',
    variable_salary: 'Salari variable',
    flexible_products: 'Productes flexibles',
    without_remuneration: 'Sense pla de remuneració flexible',
    with_remuneration: 'Amb pla de remuneració flexible',
    non_flexible_products: 'Altres productes',
    total_gross_salary: 'Total salari brut',
    social_security: 'Seguretat social',
    pit: 'IRPF',
    withholding_account: 'Retenció a compte',
    net_salary: 'Salari net',
    net_available_salary: 'Salari net disponible',
    employee_bag: 'Borsa flexible',
    employee_top_bag: 'Borsa productes top',
    employee_user_bag: 'Borsa usuari',
    company_bag: 'Borsa empresa',
    contribution_company: 'Contrib. empresa',
    available: 'Disponible',
    spent: 'Gastat',
  },
  products: {
    health_insurance: 'Assegurança de Salut',
    month_text: 'Introdueixi la quantitat disponible mensual',
    min_amount_text: 'Import mínim mensual per contractar',
    max_available: 'Màxim disponible mensual',
    transport_max_text: "El limiti de despesa són 1500€ a l'any",
    start_date: 'Data d´inici',
    privative_use: 'Ús privatiu',
    year: 'Any',
    years: 'Anys',
    vehicle_value: 'Valor del vehicle',
    with_no_tax: 'Quota sense IVA',
    with_tax: 'Quota amb IVA',
    number_tickets: 'Nombre de tiquets',
    price_tickets: 'Preu per tiquet',
    death_capital: 'Capital per mort',
    capital_disability: 'Capital per invalidesa (opcional)',
    yearly_price: 'Prima anual',
    select_children:
      'Si us plau, seleccioni els nens que li agradaria afegir per a guarderia',
    no_children: 'No té fills menors de 3 anys',
    person: 'Persona',
    monthly: 'Mensual',
    yearly: 'Anual',
    main_benefit_error: 'Si us plau, comprovi abans el principal benefici',
    benefit_error: 'Ha de seleccionar el benefici',
    price_over_error: 'Supera la quantitat disponible',
  },
  contracts: {
    month: 'Mes',
    company: 'Companyia',
    capital_death_case: 'Capital per mort',
    capital_dis_case: 'Capital per invalidesa',
    pending_text:
      'El producte està sent revisat. L"informarem quan estigui contractat',
    contract_text: 'Ja ha contractat el benefici',
    zero_message: 'La quantitat total es 0',
    min_unreached: 'No s´ha arribat a la quantitat mínima mensual',
    reset_message: 'La configuració ha estat ressetejada',
    update_confirm_message: `
                Canviaràs les condicions d'un contracte ja existent, estas segur?” En cas afirmatiu, revisa el teu correu per completar el procés`,
    select_start_month:"selecciona el mes d'inici",
    inactive_product:"No tens permís per llogar aquest producte. Poseu-vos en contacte amb l'administrador per activar-lo.",
  },
  cart: {
    data_confirm: 'Confirmació de dades',
    sign_contract: 'Firma de contracte',
    step_company: 'Selecció de Companyia',
    step_product: 'Selecció de Producte',
    step_family: 'Selecció de Família',
    empty_cart: 'La teva cistella està buida',
    agree_message:
      'En fer clic a següent pas, vostè accepta contractar automàticament',
    terms: `Firma i <b><u>rebràs un correu electrònic amb el contracte de novació adjunt</u></b> i la relació de productes seleccionats que hauràs d'acceptar.
          <br />
          <br />
          Per poder finalitzar el teu procés de contractació, <b><u>cal que confirmeu les condicions al correu electrònic.</u></b> Recorda fer-ho abans del dia&nbsp;
          {{cutOffDate}} per disposar del producte l'1 del mes següent.`,
    pending: 'PENDENT',
    completed: 'COMPLETAT',
    download_doc_text:
      "Si us plau, descarregui el document, empleni'l, signi'l i adjunti'l.",
    edit_family: 'Editar informació del beneficiari',
    name: 'Nom',
    surname: 'Cognom',
    address: 'Direcció',
    zip: 'Codi Postal',
    contact_person: 'Persona de contacte',
    contact_phone: 'Telèfon de contacte',
    center_name: 'Nom del centre',
    course: 'Curs',
    phone_contact: 'Telèfon de contacte',
    attach_invoice: 'Si us plau adjunteu la factura',
    same_kindergarten: 'Es la mateixa guarderia que',
    code_center: 'Codi del centre',
    dontknow: 'Si no ho sap, feu clic aqui',
    edit_profile: 'Editar beneficiari',
    modality_info_details: 'Informació detalllada',
    modality: 'Modalitat',
    beneficiary_info: 'Informació de beneficiari',
    additional_info: 'Informació adicional',
    total: 'Total',
    total_anual: 'Total Anual',
    total_spent: 'TOTAL GASTAT',
    flexible_bag: 'Bossa productes top',
    top_bag: 'Bossa productes top',
    company_bag: 'BORSA EMPRESA',
    health_provider_noselect:
      "no es pot seleccionar aquest proveïdor perquè l'usuari ja ha contractat un altre proveïdor",
    no_info: 'No és necessària informació',
    expired_products: 'Tens productes al carretó amb dates inferiors a la data de tancament de l’empresa. Elimineu-los per poder contractar-los: ',
    expired_warning: 'CADUCAT! Si us plau, traieu-lo',
    time_left: 'Temps restant',
    days: 'die(s)',
    remove_products: 'Si us plau, traieu aquests productes del carretó abans de contractar',
    no_permission: 'Ja no teniu permís per contractar aquest producte',
    payment_frequency: 'Freqüència de pagament'
  },
  prices:{
    price: 'Preu',
    discount: 'Desc.',
    total: 'Total'
  }

}
