import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withTranslation, WithTranslation } from 'react-i18next'

import MediaQuery from 'react-responsive'

import { logout } from 'store/auth/actions'
import { selectProfile } from 'store/profile/reducer'
import { getCart } from 'store/cart/reducer'
import Header from './Header'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

import './Layout.scss'

type Props = {
  innerComponent: any
  pageName?: string
  user: User
}

class Layout extends React.Component<Props & WithTranslation> {
  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }

  handleLogout = () => {
    logout()
  }

  render() {
    const { user } = this.props
    const { company } = user
    const InnerComponent = this.props.innerComponent

    return (
      user && company ? (
        <React.Fragment>
          <Helmet>
            <title>{company.name}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          </Helmet>
          <MediaQuery minWidth={768}>
            <Sidebar user={user} />
          </MediaQuery>

          <MediaQuery maxWidth={768}>
            <Topbar {...this.props} logout={this.handleLogout} />
          </MediaQuery>

          <div id="page-wrapper" className="main-content">
            <MediaQuery minWidth={768}>
              <Header {...this.props} logout={this.handleLogout} />
            </MediaQuery>
            <div className="page-content">
              <InnerComponent {...this.props} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="page-content">
            <div className="alert alert-danger" role="alert">
              <b>error</b>: user was not found or company missing for this user
              <button type="button" className="mx-2 btn btn-danger" onClick={this.handleLogout}>
                logout
              </button>
            </div>
          </div>

        </React.Fragment>
      )
    )
  }
}

const mapStateToProps = state => ({
  user: selectProfile(state),
  cart: getCart(state),
})

export default withTranslation()(connect(mapStateToProps)(Layout))
