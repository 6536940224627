/* eslint-disable */
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { getTranslate } from 'helpers/translate'
import { formatCurrency } from 'utils/number'
import { removeItem } from 'store/cart/actions'
import { useTranslation } from 'react-i18next'
import './Topbar.scss'

type Props = {
  user: User
  location: {
    pathname: string
  }
  cart: Contract[]
  logout(): void
  dispatch(action: any): void
}

const isActive = (path, location) => (path === location ? true : false)
const Topbar: React.FC<Props> = props => {
  const { user, cart, location } = props

  const disableLanguage = process.env.REACT_APP_DISABLE_LANGUAGE === '1'

  const { t, i18n } = useTranslation()

  const { pathname } = location

  const { company } = user
  const style: any = {}
  const styleActive: any = {}

  const isHome = pathname == '/home'

  if (company.colorBar) {
    style.backgroundColor = company.colorBar
    styleActive.background = `color-mix(${company.colorBar}, #000, 50%)`
  }

  const languages = [
    { code: 'es', name: 'Español' },
    { code: 'ca', name: 'Catalan' },
    { code: 'en', name: 'English' },
    { code: 'pt', name: 'Português' },
  ]

  const supportedLanuages = languages.filter(
    item => company.languages && company.languages.indexOf(item.code) >= 0
  )

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  const handleRemoveItem = (e, item) => {
    e.preventDefault()
    props.dispatch(removeItem(item.id))
  }

  return (
    <nav className="navbar navbar-default navbar-fixed-top" style={style}>
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <button
            type="button"
            className="navbar-toggle collapsed button-cart "
            data-toggle="collapse"
            data-target="#navbar-menu-cart"
            aria-expanded="false"
          >
            <i className="entypo-basket shop-cart-mobile" />

            <span
              className={`badge ${cart.length &&
                'badge-success'} chat-notifications-badge`}
            >
              {cart.length}
            </span>
          </button>

          <button
            type="button"
            className="logout-mobile-button"
            onClick={props.logout}
          >
            <span className="cp" title="Log out">
              {/* <i className="logout-mobile entypo-logout"/> */}
              <img src="/img/out.svg" alt="logout" width="23" />
            </span>
          </button>


          <button
            type="button"
            className="navbar-toggle collapsed button-help"
            data-toggle="collapse"
            data-target="#navbar-menu-help"
            aria-expanded="false"
          >
            {/*{t('HEADER.HELP')}*/}
            <i className="help-mobile entypo-help" />

          </button>

          {!isHome && (
            <Link to="/">
              <button
                type="button"
                className="ml-3 pull-left navbar-toggle collapsed"
                aria-expanded="false"
              >
                <i className="entypo-left-open-big" />
              </button>
            </Link>
          )}

          {isHome && (
            <Link to="/" className="navbar-brand pt-3" href="#">
              {company.name}
            </Link>
          )}
        </div>

        <div
          className="collapse navbar-collapse cb-navbar-content"
          id="navbar-menu-help"
        >
          <ul className="nav navbar-nav">
            <li>
              <a href="mailto:retribucionflexible@confide.es">
                <i className="entypo-mail" /> retribucionflexible@confide.es
              </a>
            </li>
          </ul>
        </div>

        <div
          className="collapse navbar-collapse cb-navbar-content"
          id="navbar-menu-cart"
        >
          <ul className="nav navbar-nav">
            {cart.map(item => {
              return (
                <li key={item.id}>
                  <div className="cart-item">
                    {item.benefit && item.benefit.logo && (
                      <span className="image">
                        <img
                          src={`/api/storage/images/download/${item.benefit.logo}`}
                          width="44"
                          alt="card"
                        />
                      </span>
                    )}
                    <span className="pl-2">
                      {item.benefit &&
                        getTranslate(item.benefit, 'name', i18n.language)}
                    </span>
                    <span className="cost ml-auto">
                      {formatCurrency(item.price)}
                    </span>

                    <button
                      className="btn btn-danger button-remove-item ml-3"
                      onClick={e => {
                        handleRemoveItem(e, item)
                      }}
                    >
                      <i className="entypo-erase minus-circled-big" />
                    </button>
                  </div>
                </li>
              )
            })}

            <li className="checkout-item text-right">
              <Link to="/cart" className="p-0">
                <button className="btn btn-default">
                  {t('HEADER.CHECKOUT')}
                </button>
              </Link>
            </li>
          </ul>
        </div>

        <div
          className="collapse navbar-collapse cb-navbar-content"
          id="navbar-menu"
        >
          <ul className="nav navbar-nav">
            {!disableLanguage && (
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={`/assets/images/flags/flag-${i18n.language}.png`}
                    alt="flag"
                    width="16"
                    height="16"
                  />{' '}
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  {supportedLanuages.map(lang => (
                    <li
                      key={lang.code}
                      className={i18n.language === lang.code ? 'active' : ''}
                    >
                      <a
                        onClick={() => {
                          changeLanguage(lang.code)
                        }}
                      >
                        <img
                          src={`/assets/images/flags/flag-${lang.code}.png`}
                          alt="flag"
                          width="16"
                          height="16"
                        />
                        <span>{lang.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <li className={`root-level`} style={isActive(pathname, '/home') ? styleActive : {}}>
              <Link to="/home">
                <i className="entypo-home" />
                <span className="title">{t('MENU.HOME')}</span>
              </Link>
            </li>
            <li className={`root-level`} style={isActive(pathname, '/documents') ? styleActive : {}}>
              <Link to="/documents">
                <i className="entypo-drive" />
                <span className="title">{t('MENU.DOCUMENT')}</span>
              </Link>
            </li>
            <li className={`root-level`} style={isActive(pathname, '/profile') ? styleActive : {}}>
              <Link to="/profile">
                <i className="entypo-user" />
                <span className="title">{t('MENU.PROFILE')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default withRouter(Topbar)
